import { Timelapse } from "@mui/icons-material"
import Button from "core/components/new/Button"
import { STR_CANCELLED_TYPE, STR_PAST_TYPE, STR_UPCOMING_TYPE } from "core/constants/strings"
import { CalendarIcon, ClockIcon } from "core/constants/svgs"
import { format, parseISO } from "date-fns"

export default function SessionCard({ data, type, onReview, onFeedback, onCancelled, onMenteeAbsent }: any) {
  const formatDate = (date: any) => {
    if (typeof date === "string") {
      // If the date is an ISO string, parse and format it
      return format(parseISO(date), "EEEE dd/LL/yyyy")
    } else if (typeof date === "number") {
      // If the date is a timestamp, convert and format it
      return format(new Date(date), "EEEE dd/LL/yyyy")
    } else {
      return "" // Return an empty string if the date is not valid
    }
  }
  const formatTime = (time: number | undefined) => {
    if (!time && time !== 0) return "" // handle undefined or null cases
    const hours = Math.floor(time) // Get the integer part (hours)
    const minutes = Math.round((time - hours) * 60) // Get the decimal part, convert to minutes

    // Format the time to ensure two digits for minutes
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`
  }
  const formattedDate = formatDate(data?.date || data?.session_date)
  const isMenteeAbsentAllowed = Date.now() >= data?.start_timestamp * 1000 + 15 * 60 * 1000
  return (
    <div className="flex items-end justify-between p-6 text-new-neutral-dark shadow">
      <div className="space-y-4">
        <h3 className="text-new-accent">Mentor Session</h3>
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <span>
              <CalendarIcon className="w-6" />
            </span>
            <p>{formattedDate}</p>
          </div>
          <div className="flex items-center gap-2">
            <span>
              <ClockIcon className="w-6" />
            </span>
            <p>
              {formatTime(data?.start || data?.session_start_time)} - {formatTime(data?.end || data?.session_end_time)}
            </p>
          </div>
          {type === STR_PAST_TYPE && (
            <div className="flex items-center gap-2">
              <span>
                <Timelapse className="w-6" />
              </span>
              <p>{data?.duration} minutes</p>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-0.5">
          <div className="inline-flex gap-1 text-sm">
            Student Email: <div className="font-semibold">{data?.student_id}</div>
          </div>
          <div className="inline-flex gap-1 text-sm">
            Student Name: <div className="font-semibold">{data?.name}</div>
          </div>
          <div className="inline-flex gap-1 text-sm">
            Batch: <div className="font-semibold">{data?.batch}</div>
          </div>
          <div className="inline-flex gap-1 text-sm">
            Phone: <div className="font-semibold">{data?.phone}</div>
          </div>
        </div>
      </div>
      {type !== STR_CANCELLED_TYPE && (
        <div className="flex items-center gap-2">
          {type === STR_UPCOMING_TYPE && (
            <>
              {data?.id ? (
                <Button
                  disabled={!isMenteeAbsentAllowed || data?.session_status === "Mentee Absent"}
                  outlined
                  failure={isMenteeAbsentAllowed}
                  className="w-fit"
                  onClick={() => onMenteeAbsent(data?.id)}
                >
                  {data?.session_status === "Mentee Absent" ? "Mentee Absent" : "Mark Mentee Absent"}
                </Button>
              ) : (
                <></>
              )}
              <Button outlined failure className="w-fit" onClick={() => onCancelled(data?.index)}>
                Cancel Booking
              </Button>
              <Button outlined className="w-fit" onClick={() => window.open(data?.meet_link)}>
                Join Meeting
              </Button>
            </>
          )}
          {type === STR_PAST_TYPE && (
            <>
              {data?.id ? (
                <Button
                  disabled={!isMenteeAbsentAllowed || data?.session_status === "Mentee Absent"}
                  outlined
                  failure={isMenteeAbsentAllowed}
                  className="w-fit"
                  onClick={() => onMenteeAbsent(data?.id)}
                >
                  {data?.session_status === "Mentee Absent" ? "Mentee Absent" : "Mark Mentee Absent"}
                </Button>
              ) : (
                <></>
              )}
              <Button outlined className="w-fit" onClick={() => onReview(data?.index)}>
                View Review
              </Button>
              <Button outlined className="w-fit" onClick={() => onFeedback(data?.index)}>
                {data?.mentor_feedback?.feedback || data?.mentor_feedback?.action_items
                  ? "Edit Feedback"
                  : "Give Feedback"}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
