import React, { useEffect, useState } from "react"
import DashboardLayout from "core/layouts/DashboardLayout"
import CommunityAnnouncementsViewModel from "./CommunityAnnouncementsViewModel"
import Toast from "core/components/Toast"
import Spinner from "core/components/Spinner"

export default function CommunityAnnouncements() {
  const {
    getBatches,
    batch,
    handleInputChange,
    emailData,
    sendEmailToAll,
    setEmailData,
    toast,
    error,
    sending,
    sendEmailToProgramme,
    sendEmailToBatch,
    sendEmailToGroupOfStudents,
    changeToastVisibility,
    messageData,
    handleCheckboxChangeForDisabled,
    isCheckedForDisabled,
    handleCheckboxChangeFoBatch,
    isCheckedForBatch,
    setAttachments,
    attachments,
    handleFileChange,
    isLogoVisible,
    setLogoVisible,
    setImageFile,
    sign,
  } = CommunityAnnouncementsViewModel()

  useEffect(() => {
    getBatches()
  }, [])

  const [isPopupVisible, setPopupVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState("") // New state to track selected option

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible)
    setEmailData({
      ...emailData,
      emailBody: `${emailData.emailBody}\n\nRegards,\nBosscoder Academy`,
    })
  }
  const removeLogo = () => {
    setLogoVisible(false)
  }

  const handleOptionSelection = (option: string) => {
    setSelectedOption(option)
    togglePopup()
  }

  const handleSubmittoAll = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    console.log("Email Data:", emailData)
    console.log("Attachment:", attachments)
    togglePopup()
    sendEmailToAll(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
  }

  const handleSubmitToBatch = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToBatch(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
  }
  const handleSubmitToGroupOfStudents = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToGroupOfStudents(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
  }
  const handleSubmittoProgramme = () => {
    if (isLogoVisible) {
      setImageFile(sign)
    }
    togglePopup()
    sendEmailToProgramme(sign)
    setEmailData({
      subject: "",
      emailBody: "",
      program: "",
      batch: "",
      emailAddresses: "",
      attachments: [],
      includeDisabled: false,
      includeCoursePaused: false,
    })
  }
  function handleCancel() {
    setSelectedOption("")
    setEmailData({
      ...emailData,
      emailBody: "",
    })
    setPopupVisible(false)
  }

  return (
    <DashboardLayout>
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="p-6">
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h3 className="text-lg font-semibold mb-4">Send Email to Students</h3>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setPopupVisible(true)}
          >
            Compose Your Email
          </button>
        </div>

        {isPopupVisible && !selectedOption && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/3">
              <h3 className="text-xl font-semibold mb-4">Send Email to:</h3>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("All Students")}
              >
                All Students
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("Particular Programme")}
              >
                Particular Programme
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("Particular Batch")}
              >
                Particular Batch
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 mb-4 w-full rounded hover:bg-blue-600"
                onClick={() => handleOptionSelection("Group of Students")}
              >
                Group of Students
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 w-full rounded hover:bg-gray-400"
                onClick={() => {
                  setPopupVisible(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {selectedOption && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/2">
              <h3 className="text-xl font-semibold mb-4">Compose Your Email - {selectedOption}</h3>

              {/* Subject Input */}
              <label className="block mb-2 font-medium">Subject</label>
              <input
                type="text"
                name="subject"
                value={emailData.subject}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                placeholder="Enter the subject"
              />

              {/* Email Body */}
              <label className="block mb-2 font-medium">Email Body</label>
              <textarea
                name="emailBody"
                value={emailData.emailBody}
                onChange={handleInputChange}
                className="w-full p-2 mb-4 border border-gray-300 rounded"
                placeholder="Enter your email content"
                rows={5}
              ></textarea>

              {/* Conditional Form Inputs */}
              {selectedOption === "Particular Programme" && (
                <div>
                  <label htmlFor="file">Attachment</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  <label className="block mb-2 font-medium">Select Programme</label>
                  <select
                    name="program"
                    value={emailData.program}
                    onChange={handleInputChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                  >
                    <option value="">Select a Programme</option>
                    <option value="Accelerator">Accelerator</option>
                    <option value="Transformer">Transformer</option>
                    <option value="Data Engineering">Data Engineering</option>
                    <option value="Evolve">Evolve</option>
                    <option value="Data Analytics">Data Analytics</option>
                    <option value="Data Science">Data Science</option>
                  </select>
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <label htmlFor="except disabled">Include Disabled</label>
                  <input
                    type="checkbox"
                    id="include disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForDisabled}
                    onChange={handleCheckboxChangeForDisabled}
                  />{" "}
                  <br />
                  <label htmlFor="except disabled">Include Course Paused</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForBatch}
                    onChange={handleCheckboxChangeFoBatch}
                  />
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmittoProgramme}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {selectedOption === "All Students" && (
                <div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <label htmlFor="except disabled">Include Disabled</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForDisabled}
                    onChange={handleCheckboxChangeForDisabled}
                  />{" "}
                  <br />
                  <label htmlFor="except disabled">Include Course Paused</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForBatch}
                    onChange={handleCheckboxChangeFoBatch}
                  />
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmittoAll}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {selectedOption === "Particular Batch" && (
                <div>
                  <label htmlFor="file">Attachment</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  <label className="block mb-2 font-medium">Select Batch</label>
                  <select
                    name="batch"
                    value={emailData.batch}
                    onChange={handleInputChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                  >
                    <option value="">Select a Batch</option>
                    {batch?.map((batchOption: any, index: number) => (
                      <option value={batchOption} key={index}>
                        {batchOption}
                      </option>
                    ))}
                  </select>
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <label htmlFor="except disabled">Include Disabled</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForDisabled}
                    onChange={handleCheckboxChangeForDisabled}
                  />{" "}
                  <br />
                  <label htmlFor="except disabled">Include Course Paused</label>
                  <input
                    type="checkbox"
                    id="except disabled"
                    className="ml-2 mt-1"
                    checked={isCheckedForBatch}
                    onChange={handleCheckboxChangeFoBatch}
                  />
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmitToBatch}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}

              {selectedOption === "Group of Students" && (
                <div>
                  <label className="block mb-2 font-medium">Email Addresses (comma-separated)</label>
                  <input
                    type="email"
                    name="emailAddresses"
                    onChange={handleInputChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    placeholder="Enter email addresses"
                  />
                  {error && <p className="text-red-500">{error}</p>} {/* Show error message */}
                  <label htmlFor="file">Attachement</label>
                  <input
                    type="file"
                    id="file"
                    onChange={handleFileChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    multiple
                  />
                  {isLogoVisible && (
                    <div className="mb-4">
                      <img src={sign} alt="Bosscoder Logo" className="h-[40px] mb-2 w-[150px]" />
                      <button onClick={removeLogo} className="text-red-500 hover:underline">
                        Remove Logo
                      </button>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <button
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      onClick={handleSubmitToGroupOfStudents}
                    >
                      {sending ? (
                        <span className="flex">
                          <span>Sending Email </span>
                          <Spinner small={true} />
                        </span>
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}
