import React from "react"
import ButtonGroup from "core/components/ButtonGroup"
import DashboardLayout from "core/layouts/DashboardLayout"
import useAllSessionViewModel from "./AllSessionViewModel"
import SessionCard from "./components/SessionCard"
import Loader from "core/components/Loader"
import ConditionalLoader from "core/components/ConditionalLoader"
import Modal from "core/components/Modal"
import Button from "core/components/new/Button"
import { STR_CANCELLED_TYPE, STR_PAST, STR_PAST_TYPE, STR_UPCOMING, STR_UPCOMING_TYPE } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import Toast from "core/components/Toast"
import { StarIcon } from "core/constants/svgs"
import Input from "core/components/new/Input"
import { useApp } from "core/context/app"
import ConfirmationPopup from "../MyMentees/components/ConfirmationPopup"

export default function AllSessionView() {
  const {
    TABS,
    activeTab,
    upcomingSessions,
    pastSessions,
    cancelledSessions,
    refreshed,
    loading,
    reviewIndex,
    feedbackIndex,
    cancelIndex,
    openModal,
    modalLoading,
    toast,
    mentorFeedback,
    mentorActionItems,
    MENTOR_SESSION_TYPES,
    sessionActiveTab,
    upcomingGoalSettingSessions,
    pastGoalSettingSesions,
    isGoalSettingMentor,
    mentor,
    isOpen,
    setIsGoalSettingMentor,
    cancelMentorSessionByMentorOld,
    handleTabChange,
    handleSessionTabChange,
    fetchMentorSessions,
    handleReviewIndexChange,
    handleFeedbackIndexChange,
    handleCancelIndexChange,
    handleCancelSessionOld,
    handleCloseModal,
    handleCancelSession,
    changeToastVisibility,
    handleFeedbackChange,
    handleActionItemsChange,
    setMentorFeedback,
    setMentorActionItems,
    handleSubmitFeedback,
    cancelMentorSessionByMentor,
    formatTime,
    setIsOpen,
    markMenteeAbsent,
    indexToBeMarkedAbsent,
    setIndexToBeMarkedAbsent,
    isCancellationInProgress,
  } = useAllSessionViewModel()
  const [newMentorshipFlow, setNewMentorshipFlow] = React.useState(false)
  const [idToBeCancelled, setIdToBeCancelled] = React.useState("")
  React.useEffect(() => {
    if (mentor?.mentor?.goal_setting_mentor) {
      setIsGoalSettingMentor(true)
    }
  }, [])
  React.useEffect(() => {
    if (refreshed) {
      fetchMentorSessions()
      setNewMentorshipFlow(true)
    }
  }, [refreshed, isCancellationInProgress, activeTab, sessionActiveTab])

  React.useEffect(() => {
    if (feedbackIndex !== -1) {
      const feedback = pastSessions[feedbackIndex]?.mentor_feedback?.feedback
      const actionItems = pastSessions[feedbackIndex]?.mentor_feedback?.action_items
      setMentorFeedback(feedback ?? "")
      setMentorActionItems(actionItems ?? "")
    }
  }, [feedbackIndex])
  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          // email={student?.email}
          updateStudentsMentor={() => markMenteeAbsent(indexToBeMarkedAbsent)}
          heading="Are you sure you want to mark mentee absent for this session?"
        />
      )}
      {isCancellationInProgress ? (
        <div className="flex flex-col gap-4 justify-center items-center">
          <div>
            <Loader />
          </div>
          <div className="text accent-blue text-lg">Please wait! Your session is being cancelled</div>
        </div>
      ) : (
        <>
          <div className="space-y-6 p-6">
            <h2 className="text-new-accent">All Mentor Sessions</h2>
            {isGoalSettingMentor ? (
              <ButtonGroup buttons={MENTOR_SESSION_TYPES} active={sessionActiveTab} onChange={handleSessionTabChange} />
            ) : (
              <></>
            )}

            <ButtonGroup buttons={TABS} active={activeTab} onChange={handleTabChange} />
            <div className="space-y-4">
              {loading ? (
                <Loader />
              ) : MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions" && TABS[activeTab] === STR_UPCOMING ? (
                <div className="space-y-4">
                  <ConditionalLoader
                    isLoading={isEmpty(upcomingSessions)}
                    loader={<div className="flex h-40 items-center justify-center">No upcoming sessions</div>}
                  >
                    {upcomingSessions
                      ?.filter((session: any) => !session?.id?.includes("msid1")) // Filter out sessions with id containing "msid1"
                      .map((session: any, index: number) => (
                        <SessionCard
                          key={session?.room_id}
                          data={{ ...session, index }}
                          type={STR_UPCOMING_TYPE}
                          onCancelled={() => handleCancelIndexChange(index)}
                          onMenteeAbsent={() => {
                            setIsOpen(true)
                            setIndexToBeMarkedAbsent(session?.id)
                          }}
                        />
                      ))}
                  </ConditionalLoader>
                </div>
              ) : TABS[activeTab] === STR_UPCOMING &&
                MENTOR_SESSION_TYPES[sessionActiveTab] === "Goal Setting Sessions" ? (
                <div className="space-y-4">
                  <ConditionalLoader
                    isLoading={isEmpty(upcomingGoalSettingSessions)}
                    loader={<div className="flex h-40 items-center justify-center">No upcoming sessions</div>}
                  >
                    {upcomingGoalSettingSessions
                      ?.filter((session: any) => session?.id?.includes("msid1")) // Filter out sessions with id containing "msid1"
                      .map((session: any, index: number) => (
                        <SessionCard
                          key={session?.room_id}
                          data={{ ...session, index }}
                          type={STR_UPCOMING_TYPE}
                          onCancelled={() => handleCancelIndexChange(index)}
                          onMenteeAbsent={() => {
                            setIsOpen(true)
                            setIndexToBeMarkedAbsent(session?.id)
                          }}
                        />
                      ))}
                  </ConditionalLoader>
                </div>
              ) : TABS[activeTab] === STR_PAST && MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions" ? (
                <div className="space-y-4">
                  <ConditionalLoader
                    isLoading={isEmpty(pastSessions)}
                    loader={<div className="flex h-40 items-center justify-center">No past sessions</div>}
                  >
                    {pastSessions
                      ?.filter((session: any) => !session?.id?.includes("msid1")) // Filter out sessions with id containing "msid1"
                      .map((session: any, index: number) => (
                        <SessionCard
                          onFeedback={() => handleFeedbackIndexChange(index)}
                          key={session?.room_id}
                          onReview={() => handleReviewIndexChange(index)}
                          data={{ ...session, index }}
                          type={STR_PAST_TYPE}
                          onCancelled={() => handleCancelIndexChange(index)}
                          onMenteeAbsent={() => {
                            setIsOpen(true)
                            setIndexToBeMarkedAbsent(session?.id)
                          }}
                        />
                      ))}
                  </ConditionalLoader>
                </div>
              ) : TABS[activeTab] === STR_PAST && MENTOR_SESSION_TYPES[sessionActiveTab] === "Goal Setting Sessions" ? (
                <div className="space-y-4">
                  <ConditionalLoader
                    isLoading={isEmpty(pastSessions)}
                    loader={<div className="flex h-40 items-center justify-center">No past sessions</div>}
                  >
                    {pastSessions
                      ?.filter((session: any) => session?.id?.includes("msid1")) // Filter out sessions with id containing "msid1"
                      .map((session: any, index: number) => (
                        <SessionCard
                          onFeedback={() => handleFeedbackIndexChange(index)}
                          key={session?.room_id}
                          data={{ ...session, index }}
                          type={STR_PAST_TYPE}
                          onReview={() => handleReviewIndexChange(index)}
                          onCancelled={() => handleCancelIndexChange(index)}
                          onMenteeAbsent={() => {
                            setIsOpen(true)
                            setIndexToBeMarkedAbsent(session?.id)
                          }}
                        />
                      ))}
                  </ConditionalLoader>
                </div>
              ) : MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions" && TABS[activeTab] === "Cancelled" ? (
                <div className="space-y-4">
                  <ConditionalLoader
                    isLoading={isEmpty(cancelledSessions)}
                    loader={<div className="flex h-40 items-center justify-center">No cancelled sessions</div>}
                  >
                    {cancelledSessions
                      ?.filter((session: any) => !session?.id?.includes("msid1"))
                      ?.map((session: any, index: number) => (
                        <SessionCard key={session?.room_id} data={{ ...session, index }} type={STR_CANCELLED_TYPE} />
                      ))}
                  </ConditionalLoader>
                </div>
              ) : (
                <div className="space-y-4">
                  <ConditionalLoader
                    isLoading={isEmpty(cancelledSessions)}
                    loader={<div className="flex h-40 items-center justify-center">No cancelled sessions</div>}
                  >
                    {cancelledSessions
                      ?.filter((session: any) => session?.id?.includes("msid1"))
                      ?.map((session: any, index: number) => (
                        <SessionCard key={session?.room_id} data={{ ...session, index }} type={STR_CANCELLED_TYPE} />
                      ))}
                  </ConditionalLoader>
                </div>
              )}
            </div>
          </div>
          <Modal open={openModal} onClose={handleCloseModal}>
            {cancelIndex !== -1 && (
              <div className="flex flex-col gap-4">
                <div className="space-y-2">
                  <h3 className="text-new-accent">Cancel Session</h3>
                  <p>
                    Are you sure you want to cancel session on{" "}
                    {MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                      ? upcomingSessions[cancelIndex]?.date
                      : upcomingGoalSettingSessions[cancelIndex]?.date}{" "}
                    from{" "}
                    {MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                      ? formatTime(upcomingSessions[cancelIndex]?.start)
                      : formatTime(upcomingGoalSettingSessions[cancelIndex]?.start)}{" "}
                    to{" "}
                    {MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                      ? formatTime(upcomingSessions[cancelIndex]?.end)
                      : formatTime(upcomingGoalSettingSessions[cancelIndex]?.end)}
                    ?
                  </p>
                </div>
                <div className="flex items-center gap-2 self-end">
                  <Button outlined failure className="w-20" onClick={handleCloseModal}>
                    No
                  </Button>
                  <Button
                    outlined
                    className="w-20"
                    loading={modalLoading}
                    onClick={
                      newMentorshipFlow
                        ? () =>
                            cancelMentorSessionByMentor(
                              MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                                ? upcomingSessions[cancelIndex]?.id || upcomingSessions[cancelIndex]?.room_id
                                : upcomingGoalSettingSessions[cancelIndex]?.id ||
                                    upcomingGoalSettingSessions[cancelIndex]?.room_id,
                              "cancel",
                              MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                                ? upcomingSessions[cancelIndex]?.student_id
                                : upcomingGoalSettingSessions[cancelIndex]?.student_id
                            )
                        : () => {
                            cancelMentorSessionByMentorOld(
                              upcomingSessions[cancelIndex]?.date,
                              upcomingSessions[cancelIndex]?.start,
                              upcomingSessions[cancelIndex]?.end
                            )
                          }
                    }
                  >
                    Yes
                  </Button>
                </div>
              </div>
            )}
            {reviewIndex !== -1 && (
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2 text-new-neutral">
                  <h3 className="text-new-accent">Student's Review</h3>
                  <p>
                    Review given by student for the mentor session on{" "}
                    {MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                      ? pastSessions[reviewIndex]?.date
                      : pastGoalSettingSesions[reviewIndex]?.date}{" "}
                    from {formatTime(pastGoalSettingSesions[reviewIndex]?.start)} to{" "}
                    {formatTime(pastSessions[reviewIndex]?.end)}.
                  </p>
                </div>
                <div className="flex items-center gap-6">
                  <p className="min-w-[80px] font-medium">Ratings</p>
                  <div className="flex gap-4">
                    {Array(5)
                      .fill(0)
                      .map((_, i) => (
                        <span key={i}>
                          <StarIcon
                            className="h-6 w-6"
                            pathClassName={
                              MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                                ? pastSessions[reviewIndex]?.student_review?.rating >= i + 1
                                  ? "fill-[#FBBF24]"
                                  : "fill-border"
                                : pastGoalSettingSesions[reviewIndex]?.student_review?.rating >= i + 1
                                  ? "fill-[#FBBF24]"
                                  : "fill-border"
                            }
                          />
                        </span>
                      ))}
                  </div>
                </div>
                <div className="flex items-start gap-6">
                  <p className="min-w-[80px] font-medium">Feedback</p>
                  <p className="h-fit w-full whitespace-pre-wrap rounded-sm border border-border bg-transparent p-4">
                    {MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions" ? (
                      !isEmpty(pastSessions[reviewIndex]?.student_review?.comment) ? (
                        pastSessions[reviewIndex]?.student_review?.comment
                      ) : (
                        <em>No feedback provided</em>
                      )
                    ) : !isEmpty(pastGoalSettingSesions[reviewIndex]?.student_review?.comment) ? (
                      pastGoalSettingSesions[reviewIndex]?.student_review?.comment
                    ) : (
                      <em>No feedback provided</em>
                    )}
                  </p>
                </div>
              </div>
            )}
            {feedbackIndex !== -1 && (
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2 text-new-neutral">
                  <h3 className="text-new-accent">Mentor's Review</h3>
                  <p>Mentor's Feedback/Suggestions For The Mentee</p>
                </div>
                <div className="flex items-center gap-6">
                  <p className="min-w-[80px] font-medium">Feedback</p>
                  {pastSessions[reviewIndex]?.mentor_feedback?.feedback ? (
                    <Input
                      type="text"
                      className="text-new-neutral-dark"
                      containerClass="w-full"
                      placeholder="Write feedback"
                      value={
                        MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                          ? mentorFeedback || pastSessions[reviewIndex]?.mentor_feedback?.feedback
                          : mentorFeedback || pastGoalSettingSesions[reviewIndex]?.mentor_feedback?.feedback
                      }
                      onChange={handleFeedbackChange}
                    />
                  ) : (
                    <Input
                      type="text"
                      className="text-new-neutral-dark"
                      containerClass="w-full"
                      placeholder="Write feedback"
                      value={mentorFeedback}
                      onChange={handleFeedbackChange}
                    />
                  )}
                </div>
                <div className="flex items-start gap-6">
                  <p className="min-w-[80px] font-medium">Action Items</p>
                  {pastSessions[reviewIndex]?.mentor_feedback?.action_items ? (
                    <textarea
                      placeholder="Write action items"
                      className="h-40 w-full border border-new-neutral text-new-neutral-dark outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      value={
                        MENTOR_SESSION_TYPES[sessionActiveTab] === "Mentor Sessions"
                          ? mentorActionItems || pastSessions[reviewIndex]?.mentor_feedback?.action_items
                          : mentorActionItems || pastGoalSettingSesions[reviewIndex]?.mentor_feedback?.action_items
                      }
                      onChange={handleActionItemsChange}
                    />
                  ) : (
                    <textarea
                      placeholder="Write action items"
                      className="h-40 w-full border border-new-neutral text-new-neutral-dark outline-none placeholder:text-new-neutral-light focus:border-new-accent focus:ring-1 focus:ring-new-accent"
                      value={mentorActionItems}
                      onChange={handleActionItemsChange}
                    />
                  )}
                </div>
                <div className="flex items-center gap-2 self-end">
                  <Button outlined failure className="w-40" onClick={handleCloseModal}>
                    Close
                  </Button>
                  <Button small className="w-40" loading={modalLoading} onClick={handleSubmitFeedback}>
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        </>
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
    </DashboardLayout>
  )
}
