import React from "react"
import Button from "core/components/Button"
import Input from "core/components/new/Input"
import DashboardLayout from "core/layouts/DashboardLayout"
import useMentorProfileViewModel from "./MentorProfilePageViewModel"
import MenteesTable from "./components/MenteesTable"
import AvailibilityTable from "./components/AvailibilityTable"
import { format } from "date-fns"
import Toast from "core/components/Toast"
import ConfirmationPopUp from "./components/ConfirmationPopUp"

export default function MentorProfilePageView() {
  const {
    searchEmail,
    mentees,
    mentor,
    comments,
    availability,
    comment,
    isAlumni,
    isMentor,
    courseType,
    toast,
    selectedFile,
    addComment,
    onChangeComment,
    GetMentorProfile,
    onChangeSearchEmail,
    onChangeMentorObj,
    setSelectedFile,
    updateMentorProfile,
    updateMenteesMentor,
    changeToastVisibility,
    handleCourseTypeChange,
    handleMentorChange,
    handleAlumniChange,
    handleProfilePictureUpload,
    upDateMentorsMentees,
  } = useMentorProfileViewModel()

  const [isOpen, setIsOpen] = React.useState<any>(false)

  React.useEffect(() => {
    const email = window.location.pathname.split("/")[4]
    GetMentorProfile(email)
  }, [])

  return (
    <DashboardLayout>
      {isOpen && (
        <ConfirmationPopUp
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          email={mentor.email}
          updateMenteesMentor={updateMenteesMentor}
          heading="Are you sure you want to remove all the mentees?"
        />
      )}
      <Toast data={toast} onClick={() => changeToastVisibility(false)} />
      <div className="px-12 py-10">
        <div className="flex flex-row gap-6">
          <Input placeholder="Search with email" value={searchEmail} onChange={onChangeSearchEmail} />
          <Button onClick={() => GetMentorProfile(searchEmail)} className="h-[40px] w-40 rounded-[8px]">
            Search
          </Button>
        </div>
        <p className="mb-4 mt-4 py-1 px-2 text-[20px] font-semibold">
          <ul className="ml-4 list-disc">
            <li>Basic Information</li>
          </ul>
        </p>
        <div className="grid grid-cols-4 gap-4">
          <Input placeholder="Email" value={mentor.email} onChange={onChangeMentorObj} id="email" />
          <Input placeholder="Bandwidth" value={mentor.bandwidth} onChange={onChangeMentorObj} id="bandwidth" />
          <Input placeholder="Name" value={mentor?.name} onChange={onChangeMentorObj} id="name" />
          <Input placeholder="Phone" value={mentor?.phone} onChange={onChangeMentorObj} id="phone" />
          <Input
            placeholder="Current company"
            value={mentor?.current_company}
            onChange={onChangeMentorObj}
            id="current_company"
          />
          <Input placeholder="Experience" value={mentor?.experience} onChange={onChangeMentorObj} id="experience" />
          <Input
            placeholder="Linkedin profile"
            value={mentor?.linkedin_profile}
            onChange={onChangeMentorObj}
            id="linkedin_profile"
            containerClass="col-span-2"
          />
          <Input
            type="file"
            onChange={handleProfilePictureUpload}
            id="mentor_profile_image"
            // containerClass="col-span-2"
            className="file:bg-none file:p-0 file:border-none"
          />
          <Input placeholder="Password" value={mentor?.password} onChange={onChangeMentorObj} id="password" />
          <Input type="text" onChange={handleProfilePictureUpload} value={mentor?.photo} containerClass="col-span-2" />

          <div className="mb-4 mt-4 flex flex-col py-1 px-2 text-[20px] font-semibold">
            <ul className="ml-4 list-disc">
              <li>Other Details</li>
            </ul>
            <div className="flex gap-x-10 px-4 py-2">
              <div className="flex flex-col">
                <h4 className="text-sm">Alumni :</h4>
                <select value={isAlumni ? "True" : "False"} onChange={handleAlumniChange} id="is_alumni">
                  <option value="" disabled>
                    is Alumni
                  </option>
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
              </div>

              <div className="flex flex-col">
                <h4 className="text-sm">Mentor :</h4>
                <select value={isMentor ? "True" : "False"} onChange={handleMentorChange} id="is_mentor">
                  <option value="" disabled>
                    is Mentor
                  </option>
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
              </div>

              <div className="flex flex-col">
                <h4 className="text-sm">Course Type :</h4>
                <select value={courseType} onChange={handleCourseTypeChange} id="course_type">
                  <option value="" disabled>
                    Course Type
                  </option>
                  <option value="SDE">SDE</option>
                  <option value="DSML">DSML</option>
                  <option value="Evolve">Evolve</option>
                  <option value="Both">SDE+DSML</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-25 flex">
          {mentees && mentees.length > 0 && (
            <div className="flex-0.5">
              <p className="mb-4 mt-8 py-1 px-2 text-[20px] font-semibold">
                <ul className="ml-4 list-disc">
                  <li>Mentees</li>
                </ul>
              </p>
              <div>
                <MenteesTable mentees={mentees} upDateMentorsMentee={upDateMentorsMentees} />
              </div>
            </div>
          )}
          {availability && Object.values(availability).some((arr) => Array.isArray(arr) && arr.length > 0) && (
            <div className="ml-20 flex-1">
              <p className="mb-4 mt-8 py-1 px-2 text-[20px] font-semibold">
                <ul className="ml-4 list-disc">
                  <li>Availibality</li>
                </ul>
              </p>
              <AvailibilityTable availability={availability} />
            </div>
          )}
        </div>
        <p className="mb-4 mt-8 py-1 px-2 text-[20px] font-semibold ">
          <ul className="ml-4 list-disc">
            <li>Comments</li>
          </ul>
        </p>
        <div className="grid grid-cols-4 gap-4">
          {comments?.map((comment: any) => {
            return (
              <div className="rounded-lg border-2 border-solid p-4 ">
                {format(new Date(comment?.date), "MMM dd / yyyy @ hh:mm a")}
                <p className="text-bold font-semibold text-new-accent "> {comment.comment} </p>
              </div>
            )
          })}
          <Input id="comment" placeholder="Add comment" value={comment} onChange={onChangeComment} />
          <Button className="h-10 w-20" onClick={addComment}>
            Add
          </Button>
        </div>
        <p className="mb-4 mt-8 py-1 px-2 text-[20px] font-semibold ">
          <ul className="ml-4 list-disc">
            <li>Remove All Mentees</li>
          </ul>
        </p>
        <div className="grid grid-cols-4 gap-4 px-4 py-2">
          <Button className="w-25 h-10" onClick={() => setIsOpen(true)}>
            Remove
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <div className="flex w-40 flex-col items-center">
          <Button className="" onClick={updateMentorProfile}>
            Submit
          </Button>
        </div>
      </div>
    </DashboardLayout>
  )
}
